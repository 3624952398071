import React from 'react';

export const LoadingSpinner = () => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="flex items-center justify-center space-x-2">
        <div className="w-4 h-4 bg-purple-500 rounded-full animate-pulse"></div>
        <div className="w-4 h-4 bg-purple-500 rounded-full animate-pulse delay-75"></div>
        <div className="w-4 h-4 bg-purple-500 rounded-full animate-pulse delay-150"></div>
      </div>
    </div>
  );
};