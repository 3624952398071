import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-black/50 border-t border-white/10 py-8">
      <div className="container mx-auto px-4 text-center text-gray-400">
        <p>© {new Date().getFullYear()} B.O.X.L.O.R.D. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;