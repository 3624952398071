import React from 'react';

interface NavItemProps {
  icon: React.ReactNode;
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const NavItem: React.FC<NavItemProps> = ({ icon, label, isActive, onClick }) => {
  return (
    <div className="group relative" onClick={onClick}>
      <div className={`p-3 transition-colors cursor-pointer ${
        isActive ? 'text-purple-400' : 'text-gray-400 hover:text-purple-400'
      }`}>
        {icon}
      </div>
      <div className="absolute left-16 top-1/2 -translate-y-1/2 px-3 py-2 bg-black/90 rounded-md text-purple-400 opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap pointer-events-none">
        {label}
      </div>
    </div>
  );
};

export default NavItem;