import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu, Home, Archive, User, ShoppingBag, MessageSquare, Rocket, Book } from 'lucide-react';
import NavItem from './navigation/NavItem';
import MenuToggle from './navigation/MenuToggle';
import { NavigationItem } from './navigation/types';

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isActive = (path: string) => location.pathname === path;

  const navigationItems: NavigationItem[] = [
    { path: '/', icon: Home, label: 'Home Base' },
    { path: '/vault', icon: Archive, label: 'The Vault' },
    { path: '/operations', icon: User, label: 'About Me' },
    { path: '/expeditions', icon: Rocket, label: 'Orbital Operations' },
    { path: '/artifacts', icon: ShoppingBag, label: 'Artifacts' },
    { path: '/chronicles', icon: Book, label: 'Chronicles' },
    { path: '/contact', icon: MessageSquare, label: 'Contact HQ' },
  ];

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  return (
    <>
      <MenuToggle isOpen={isMenuOpen} onToggle={() => setIsMenuOpen(!isMenuOpen)} />

      <nav className={`fixed top-0 left-0 h-screen bg-black/90 backdrop-blur-lg border-r border-white/10 flex flex-col items-center py-8 space-y-8 z-40 transition-transform duration-300 ${
        isMenuOpen ? 'translate-x-0 w-full md:w-16' : '-translate-x-full md:translate-x-0 w-16'
      }`}>
        <div className="text-purple-500 hover:text-purple-400 transition-colors cursor-pointer">
          <Menu size={24} />
        </div>
        
        <div className="flex flex-col space-y-6">
          {navigationItems.map((item) => (
            <NavItem 
              key={item.path}
              icon={<item.icon size={20} />}
              label={item.label}
              isActive={isActive(item.path)}
              onClick={() => handleNavigation(item.path)}
            />
          ))}
        </div>
      </nav>
    </>
  );
};

export default Navigation;