import { lazy } from 'react';
import { RouteConfig } from './types';

// Lazy load pages for better performance
const Home = lazy(() => import('@/pages/Home'));
const Vault = lazy(() => import('@/pages/Vault'));
const Mission = lazy(() => import('@/pages/Mission'));
const Artifacts = lazy(() => import('@/pages/Artifacts'));
const ContactPage = lazy(() => import('@/pages/ContactPage'));
const OrbitalOperations = lazy(() => import('@/pages/OrbitalOperations'));
const OrbitalExpeditions = lazy(() => import('@/pages/OrbitalExpeditions'));
const Blog = lazy(() => import('@/pages/Blog'));
const BlogPost = lazy(() => import('@/pages/BlogPost'));

export const routes: RouteConfig[] = [
  { path: '/', component: Home },
  { path: '/vault', component: Vault },
  { path: '/mission', component: Mission },
  { path: '/artifacts', component: Artifacts },
  { path: '/contact', component: ContactPage },
  { path: '/operations', component: OrbitalOperations },
  { path: '/expeditions', component: OrbitalExpeditions },
  { path: '/chronicles', component: Blog },
  { path: '/chronicles/:slug', component: BlogPost }
];