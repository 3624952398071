import React from 'react';
import { Menu, X } from 'lucide-react';

interface MenuToggleProps {
  isOpen: boolean;
  onToggle: () => void;
}

const MenuToggle: React.FC<MenuToggleProps> = ({ isOpen, onToggle }) => {
  return (
    <button 
      className="md:hidden fixed top-4 left-4 z-50 text-purple-500 hover:text-purple-400 transition-colors"
      onClick={onToggle}
    >
      {isOpen ? <X size={24} /> : <Menu size={24} />}
    </button>
  );
};

export default MenuToggle;